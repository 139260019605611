/**
 * @generated SignedSource<<1266ef70045a6228b03c4d86277e84c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "country",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appliaMember",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "DirectMember",
  "kind": "LinkedField",
  "name": "directMember",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v6/*: any*/),
  (v4/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DirectMemberEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectMember",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "NationalAssociationDeclaration",
    "kind": "LinkedField",
    "name": "nationalAssociationDeclaration",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "NationalAssociation",
        "kind": "LinkedField",
        "name": "nationalAssociation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Year",
        "kind": "LinkedField",
        "name": "year",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "number",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedBy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Sector",
        "kind": "LinkedField",
        "name": "sectors",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ManufacturingSite",
        "kind": "LinkedField",
        "name": "manufacturingSites",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "region",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "products",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Subsidiary",
        "kind": "LinkedField",
        "name": "subsidiaries",
        "plural": true,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NonDirectOperation",
        "kind": "LinkedField",
        "name": "nonDirectOperations",
        "plural": true,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectMemberConnection",
        "kind": "LinkedField",
        "name": "members",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "filters",
        "value": {
          "exists": true
        }
      }
    ],
    "concreteType": "DirectMemberConnection",
    "kind": "LinkedField",
    "name": "allDirectMembers",
    "plural": false,
    "selections": (v8/*: any*/),
    "storageKey": "allDirectMembers(filters:{\"exists\":true})"
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SectorConnection",
    "kind": "LinkedField",
    "name": "allSectors",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SectorEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Sector",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useNationalAssociationDeclarationReadQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useNationalAssociationDeclarationReadQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "80add874f662533914dd0fd63a47d5f7",
    "id": null,
    "metadata": {},
    "name": "useNationalAssociationDeclarationReadQuery",
    "operationKind": "query",
    "text": "query useNationalAssociationDeclarationReadQuery(\n  $id: GlobalID!\n) {\n  nationalAssociationDeclaration(id: $id) {\n    id\n    nationalAssociation {\n      name\n      id\n    }\n    year {\n      number\n      id\n    }\n    signedBy\n    date\n    sectors {\n      id\n      name\n    }\n    manufacturingSites {\n      id\n      name\n      country {\n        id\n      }\n      directMember {\n        id\n        appliaMember\n      }\n      region\n      products {\n        id\n        name\n      }\n    }\n    subsidiaries {\n      id\n      name\n      directMember {\n        id\n        appliaMember\n      }\n      country {\n        id\n      }\n    }\n    nonDirectOperations {\n      id\n      name\n      directMember {\n        id\n        appliaMember\n      }\n      country {\n        id\n      }\n    }\n    members {\n      edges {\n        node {\n          id\n          name\n          appliaMember\n        }\n      }\n    }\n  }\n  allDirectMembers(filters: {exists: true}) {\n    edges {\n      node {\n        id\n        name\n        appliaMember\n      }\n    }\n  }\n  allSectors {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "84e21a2b5e9f8e34be678766aba32ee1";

module.exports = node;
