import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Unstable_Grid2 as Grid,
} from "@mui/material/";

const DeclarationSubmitted = () => {
    return (
        <Grid
            alignItems="center"
            justifyContent="center"
            container
            sx={{
                height: "100vh",
                backgroundImage: `url("/already-submitted.jpeg.jpeg")`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                    t.palette.mode === "light"
                        ? t.palette.grey[50]
                        : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <Grid xs={3}>
                <Card>
                    <CardHeader title="Already Submitted" />
                    <CardContent>
                        <Typography variant="body1">
                            This year's submission for your organization has
                            already been submitted. Thank you for your
                            cooperation.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
export default DeclarationSubmitted;
