import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Unstable_Grid2 as Grid,
} from "@mui/material/";
import { useRouteError } from "react-router-dom";

const Error = () => {
    const error = useRouteError();

    return (
        <Grid
            alignItems="center"
            justifyContent="center"
            container
            sx={{
                height: "100vh",
                backgroundImage: `url("/pillar3.jpeg")`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                    t.palette.mode === "light"
                        ? t.palette.grey[50]
                        : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <Grid xs={3}>
                <Card>
                    <CardHeader title="Error" />
                    <CardContent>
                        <Typography variant="h5">
                            Something Went Wrong:
                        </Typography>
                        <Typography variant="caption">
                            {error?.message ||
                                error?.data ||
                                JSON.stringify(error)}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
export default Error;
