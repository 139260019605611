/**
 * @generated SignedSource<<48fa6a6aa367c83b0a4bd1d1ba0f2155>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "NationalAssociationConnection",
  "kind": "LinkedField",
  "name": "allNationalAssociations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NationalAssociationEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NationalAssociation",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "YearConnection",
  "kind": "LinkedField",
  "name": "allYears",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "YearEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Year",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "appliaMember": true,
      "exists": true
    }
  }
],
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "aggregatedSector": false
      }
    }
  ],
  "concreteType": "SectorConnection",
  "kind": "LinkedField",
  "name": "allSectors",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SectorEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Sector",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "abbreviation",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allSectors(filters:{\"aggregatedSector\":false})"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useNationalAssociationSubmitQuery",
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "DirectMemberConnection",
        "kind": "LinkedField",
        "name": "allDirectMembers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DirectMemberEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DirectMember",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "useCountryPresence_directMember"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allDirectMembers(filters:{\"appliaMember\":true,\"exists\":true})"
      },
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductConnection",
        "kind": "LinkedField",
        "name": "allProducts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useProductsNationalAssociation_product"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useNationalAssociationSubmitQuery",
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "DirectMemberConnection",
        "kind": "LinkedField",
        "name": "allDirectMembers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DirectMemberEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DirectMember",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Brand",
                    "kind": "LinkedField",
                    "name": "brands",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allDirectMembers(filters:{\"appliaMember\":true,\"exists\":true})"
      },
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductConnection",
        "kind": "LinkedField",
        "name": "allProducts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83b380c26fe105eb809733d61094bd83",
    "id": null,
    "metadata": {},
    "name": "useNationalAssociationSubmitQuery",
    "operationKind": "query",
    "text": "query useNationalAssociationSubmitQuery {\n  allNationalAssociations {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  allYears {\n    edges {\n      node {\n        id\n        number\n      }\n    }\n  }\n  allDirectMembers(filters: {exists: true, appliaMember: true}) {\n    edges {\n      node {\n        ...useCountryPresence_directMember\n      }\n    }\n  }\n  allSectors(filters: {aggregatedSector: false}) {\n    edges {\n      node {\n        id\n        name\n        abbreviation\n      }\n    }\n  }\n  allProducts {\n    edges {\n      ...useProductsNationalAssociation_product\n    }\n  }\n}\n\nfragment useCountryPresence_directMember on DirectMember {\n  id\n  name\n  brands {\n    name\n  }\n}\n\nfragment useProductsNationalAssociation_product on ProductEdge {\n  node {\n    id\n    name\n  }\n}\n"
  }
};
})();

node.hash = "d9eba02bf50ce2a90d1af14b26fcb603";

module.exports = node;
