import {
    Autocomplete,
    Card,
    CardContent,
    Container,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Suspense, lazy } from "react";

const Charts = lazy(() => import("./NationalAssociationCharts"));

const ChartsFallback = () => (
    <Container maxWidth="xl">
        <Stack>
            <Typography variant="h1">Charts</Typography>
            <Card>
                <CardContent>
                    <Autocomplete
                        disablePortal
                        loading={true}
                        options={[]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Year"
                                fullWidth={true}
                                disabled
                            />
                        )}
                    />
                </CardContent>
            </Card>
        </Stack>
    </Container>
);

export const ChartsYearFallback = () => (
    <Container maxWidth="xl">
        <Stack>
            <DataGrid rows={[]} columns={[]} loading autoHeight />
        </Stack>
    </Container>
);

const ChartsRoot = () => (
    <Suspense fallback={<ChartsFallback />}>
        <Charts />
    </Suspense>
);

export default ChartsRoot;
