import { TextField as MuiTextField } from "@mui/material";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

const TextField = ({
    control,
    fieldName,
    rules = {},
    defaultValue = "",
    parseValue = (value) => value,
    ...props
}) => {
    const {
        field: { onChange, onBlur, name, value, ref },
        fieldState: { error },
    } = useController({
        name: fieldName,
        control,
        rules: rules,
        defaultValue: defaultValue.toString(),
    });

    return (
        <MuiTextField
            onChange={(event) => onChange(parseValue(event.target.value))} // send value to hook form
            onBlur={onBlur} // notify when input is touched/blur
            value={value} // input value
            name={name} // send down the input name
            inputRef={ref} // send input ref, so we can focus on input when error appear
            error={error !== undefined}
            helperText={error?.message}
            required={rules?.required ? true : false}
            color={error ? "error" : "primary"}
            {...props}
        />
    );
};

TextField.propTypes = {
    control: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    rules: PropTypes.object,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TextField;
