const typography = {
    fontFamily: '"DM Sans", sans-serif',
    body1: {
        fontWeight: 400, // or 'bold'
    },
    h1: {
        fontWeight: 500,
        fontSize: "1.875rem",
        lineHeight: "1.5",
    },
    h2: {
        fontWeight: 500,
        fontSize: "1.5rem",
        lineHeight: "1.5",
    },
    h3: {
        fontWeight: 500,
        fontSize: "1.3125rem",
        lineHeight: "1.5",
    },
    h4: {
        fontWeight: 500,
        fontSize: "1.125rem",
        lineHeight: "1.5",
    },
    h5: {
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: "1.5",
    },
    h6: {
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: "1.5",
    },
    button: {
        textTransform: "none",
        fontWeight: "400",
    },
    subtitle1: {
        fontSize: "1rem",
        fontWeight: "400",
    },
    subtitle2: {
        fontSize: "0.875rem",
        fontWeight: "400",
    },
};

export default typography;
