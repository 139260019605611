import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { Avatar, Unstable_Grid2 as Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useRelayEnvironment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
    setLocalAccessToken,
    setLocalAccessTokenExpiration,
    setLocalRefreshToken,
} from "../../api/RelayEnvironment";
import Checkbox from "../../components/mui/Checkbox";
import PasswordField from "../../components/mui/PasswordField";
import TextField from "../../components/mui/TextField";
import useLoginMutation from "./hooks/useLoginMutation";

const validationSchema = yup.object({
    email: yup
        .string()
        .required("Email is required")
        .email("This is not a valid email format"),
    password: yup.string().required("Password is required"),
});

const Login = () => {
    const navigate = useNavigate();
    const environment = useRelayEnvironment();
    const [commitMutation, isMutationInFlight] = useLoginMutation();

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: { email: "", password: "" },
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data) => {
        commitMutation({
            variables: {
                input: { email: data.email, password: data.password },
            },
            onCompleted: ({ tokenAuth }) => {
                if (tokenAuth?.success) {
                    setLocalAccessToken(tokenAuth?.token?.token);
                    setLocalAccessTokenExpiration(
                        tokenAuth?.token?.payload?.exp
                    );
                    if (data?.rememberMe) {
                        setLocalRefreshToken(tokenAuth?.refreshToken?.token);
                    }
                    toast.success("Login successful");
                    navigate("/");
                } else {
                    tokenAuth.errors?.nonFieldErrors?.forEach((error) =>
                        setError("email", {
                            type: "manual",
                            message: error.message,
                        })
                    );
                }
            },
            updater: (store) => {
                store.invalidateStore();
            },
        });
    };

    return (
        <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url("/login.jpeg")`,
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) =>
                        t.palette.mode === "light"
                            ? t.palette.grey[50]
                            : t.palette.grey[900],
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            <Grid
                xs={12}
                sm={8}
                md={5}
                alignItems="center"
                justifyContent="center"
                container
            >
                <Grid container justifyContent="center">
                    <Grid xs="auto">
                        <Avatar sx={{ bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                    </Grid>
                    <Grid xs={12} margin={10}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                fieldName="email"
                                control={control}
                                margin="normal"
                                required
                                fullWidth
                                label="Email Address"
                                autoComplete="email"
                                autoFocus
                            />
                            <PasswordField
                                fieldName="password"
                                control={control}
                                margin="normal"
                                required
                                fullWidth
                                label="Password"
                                autoComplete="current-password"
                            />
                            <Checkbox
                                fieldName="rememberMe"
                                label="Remember me"
                                control={control}
                            />
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                loading={isMutationInFlight}
                            >
                                Sign In
                            </LoadingButton>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Login;
