import { lazy, Suspense } from "react";

const ResetPassword = lazy(() => import("./ResetPassword"));

const ResetPasswordFallback = () => <></>;

const ResetPasswordRoot = () => {
    return (
        <Suspense fallback={<ResetPasswordFallback />}>
            <ResetPassword />
        </Suspense>
    );
};

export default ResetPasswordRoot;
