import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { loadQuery, usePreloadedQuery } from "react-relay";
import RelayEnvironment from "../../../api/RelayEnvironment";

const HomeQuery = graphql`
    query useHomeQuery {
        allYears(filters: { submissionsOpen: true }, first: 1) {
            edges {
                node {
                    id
                    number
                    submissionsOpen
                    nationalAssociationDeclarations {
                        edges {
                            node {
                                nationalAssociation {
                                    name
                                }
                                declarationSubmitted
                            }
                        }
                    }
                    directMemberDeclarations {
                        edges {
                            node {
                                directMember {
                                    name
                                }
                                declarationSubmitted
                                sectorDeclarationSubmitted
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const loadUseHome = (id) =>
    loadQuery(
        RelayEnvironment,
        HomeQuery,
        { id: id },
        { fetchPolicy: "store-or-network" }
    );

const useHome = (queryRef) => {
    const data = usePreloadedQuery(HomeQuery, queryRef);
    return data;
};

useHome.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useHome;
