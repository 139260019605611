import { lazy, Suspense } from "react";
import { DirectMemberSubmitFallback } from "../submit/DirectMemberSubmitRoot";

const DirectMemberRead = lazy(() => import("./DirectMemberDeclarationRead"));

const DirectMemberDeclarationReadRoot = () => (
    <Suspense fallback={<DirectMemberSubmitFallback />}>
        <DirectMemberRead />
    </Suspense>
);

export default DirectMemberDeclarationReadRoot;
