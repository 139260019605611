/**
 * @generated SignedSource<<37afcde14fce80553e86c6c706e4abeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "YearConnection",
    "kind": "LinkedField",
    "name": "allYears",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "YearEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Year",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "filters",
        "value": {
          "appliaMember": true
        }
      }
    ],
    "concreteType": "DirectMemberConnection",
    "kind": "LinkedField",
    "name": "allDirectMembers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectMemberEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DirectMember",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "allDirectMembers(filters:{\"appliaMember\":true})"
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SectorConnection",
    "kind": "LinkedField",
    "name": "allSectors",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SectorEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Sector",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "abbreviation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aggregatedSector",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useSubmitSectorsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useSubmitSectorsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1b1da4a0efe4542ab65b6e8ff57d433f",
    "id": null,
    "metadata": {},
    "name": "useSubmitSectorsQuery",
    "operationKind": "query",
    "text": "query useSubmitSectorsQuery {\n  allYears {\n    edges {\n      node {\n        id\n        number\n      }\n    }\n  }\n  allDirectMembers(filters: {appliaMember: true}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  allSectors {\n    edges {\n      node {\n        id\n        name\n        abbreviation\n        aggregatedSector\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "aff5f7f1f6180fb95841198e1c7414c0";

module.exports = node;
