import { IconButton, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import TextField from "./TextField";

const PasswordField = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <TextField
            type={showPassword ? "text" : "password"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <EyeOff /> : <Eye />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
};

PasswordField.propTypes = {
    control: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    rules: PropTypes.object,
    defaultValue: PropTypes.string,
};

export default PasswordField;
