import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { loadQuery, useLazyLoadQuery } from "react-relay";
import RelayEnvironment from "../../../../api/RelayEnvironment";
import * as Query from "./__generated__/useDirectMemberSubmitQuery.graphql";

const DirectMemberSubmitQuery = graphql`
    query useDirectMemberSubmitQuery {
        allYears {
            edges {
                node {
                    id
                    number
                }
            }
        }
        allDirectMembers(filters: { appliaMember: true }) {
            edges {
                node {
                    id
                    name
                }
            }
        }
        allSectors {
            edges {
                node {
                    id
                    name
                    abbreviation
                }
            }
        }
        allCountries {
            edges {
                node {
                    ...useCountryPresence_country
                    nationalAssociations {
                        id
                    }
                }
            }
        }

        ...useProducts_country
    }
`;

export const useDirectMemberSubmitLoader = () =>
    loadQuery(RelayEnvironment, Query);

const useDirectMemberSubmit = () => {
    const data = useLazyLoadQuery(DirectMemberSubmitQuery);
    return data;
};

useDirectMemberSubmit.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useDirectMemberSubmit;
