import { CircularProgress, Stack } from "@mui/material";
import React, { Suspense } from "react";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import "react-toastify/dist/ReactToastify.css";

import { Outlet } from "react-router";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import RelayEnvironment from "./api/RelayEnvironment";
import "./index.css";
import router from "./routes/routes";
import ThemeProvider from "./theme/ThemeProvider";

export const AppFallback = () => {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100vh", width: "100vw" }}
        >
            <CircularProgress />
        </Stack>
    );
};

const App = () => {
    return (
        <RelayEnvironmentProvider environment={RelayEnvironment}>
            <ThemeProvider>
                <RouterProvider router={router}>
                    <Outlet />
                    <ToastContainer />
                </RouterProvider>
            </ThemeProvider>
        </RelayEnvironmentProvider>
    );
};

const AppRoot = () => (
    <Suspense fallback={<AppFallback />}>
        <App />
    </Suspense>
);

AppRoot.propTypes = {};

export default AppRoot;
