import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { loadQuery, usePreloadedQuery } from "react-relay";
import RelayEnvironment from "../api/RelayEnvironment";

const RequireMembershipQuery = graphql`
    query useRequireMembershipQuery {
        me {
            id
            email
            isSuperuser
            isStaff
            hasSubmittedDeclarationActiveYear
            directMember {
                id
                name
                declarations(filters: { declarationSubmitted: true }, last: 1) {
                    edges {
                        node {
                            id
                        }
                    }
                }
            }
            nationalAssociation {
                id
                name
                declarations(filters: { declarationSubmitted: true }, last: 1) {
                    edges {
                        node {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const loadUseRequireMembership = () =>
    loadQuery(
        RelayEnvironment,
        RequireMembershipQuery,
        {},
        {
            fetchPolicy: "network-only",
        }
    );

const useRequireMembership = (queryRef) => {
    const data = usePreloadedQuery(RequireMembershipQuery, queryRef);
    return data;
};

useRequireMembership.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useRequireMembership;
