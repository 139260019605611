import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { useFragment } from "react-relay";

export const revenueBarChartQuery = graphql`
    fragment useRevenueBarChart_year on DirectMemberDeclarationEdge
    @relay(plural: true) {
        node {
            directMember {
                name
            }
            directmembersectordeclaration {
                sector {
                    id
                    aggregatedSector
                }
                interest
                revenue
                vote
            }
        }
    }
`;

const useRevenueBarChart = (queryRef) => {
    const data = useFragment(revenueBarChartQuery, queryRef);
    return data;
};

useRevenueBarChart.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useRevenueBarChart;
