import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { loadQuery, usePreloadedQuery } from "react-relay";
import RelayEnvironment from "../../../../api/RelayEnvironment";

const NationalAssociationDeclarationRead = graphql`
    query useNationalAssociationDeclarationReadQuery($id: GlobalID!) {
        nationalAssociationDeclaration(id: $id) {
            id
            nationalAssociation {
                name
                id
            }
            year {
                number
                id
            }
            signedBy
            date
            sectors {
                id
                name
            }
            manufacturingSites {
                id
                name
                country {
                    id
                }
                directMember {
                    id
                    appliaMember
                }
                region
                products {
                    id
                    name
                }
            }
            subsidiaries {
                id
                name
                directMember {
                    id
                    appliaMember
                }
                country {
                    id
                }
            }
            nonDirectOperations {
                id
                name
                directMember {
                    id
                    appliaMember
                }
                country {
                    id
                }
            }
            members {
                edges {
                    node {
                        id
                        name
                        appliaMember
                    }
                }
            }
        }
        allDirectMembers(filters: { exists: true }) {
            edges {
                node {
                    id
                    name
                    appliaMember
                }
            }
        }
        allSectors{
            edges{
                node{
                    id
                    name
                }
            }
        }
    }
`;

export const loadUseNationalAssociationDeclarationRead = (id) =>
    loadQuery(
        RelayEnvironment,
        NationalAssociationDeclarationRead,
        { id: id },
        { fetchPolicy: "store-or-network" }
    );

const useNationalAssociationDeclarationRead = (queryRef) => {
    const data = usePreloadedQuery(
        NationalAssociationDeclarationRead,
        queryRef
    );
    return data;
};

useNationalAssociationDeclarationRead.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useNationalAssociationDeclarationRead;
