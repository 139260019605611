import { createBrowserRouter } from "react-router-dom";
import RequireAdmin from "../components/RequireAdminProvider";
import RequireMemberProvider from "../components/RequireMemberProvider";
import RequireMembershipProvider from "../components/RequireMembershipProvider";
import { loadUseLayout } from "../hooks/useLayoutQuery";
import { loadUseRequireAdmin } from "../hooks/useRequireAdmin";
import { loadUseRequireMember } from "../hooks/useRequireMember";
import { loadUseRequireMembership } from "../hooks/useRequireMembership";
import { loadUseYears } from "../hooks/useYearsQuery";
import { BreadCrumbLink } from "../layout/components/Breadcrumbs";
import HeaderLayoutRoot from "../layout/header/LayoutRoot";
import SideBarLayoutRoot from "../layout/sidebar/LayoutRoot";
import ActivateRoot from "../pages/activate/ActivateRoot";
import DeclarationSubmitted from "../pages/declaration_submitted/DeclarationSubmitted";
import DirectMemberRoot from "../pages/direct_members/DirectMemberRoot";
import ChartsRoot from "../pages/direct_members/charts/ChartsRoot";
import { loadCharts } from "../pages/direct_members/charts/hooks/useCharts";
import DirectMemberDeclarationReadRoot from "../pages/direct_members/read/DirectMemberDeclarationReadRoot";
import { loadUseDirectMemberDeclarationRead } from "../pages/direct_members/read/hooks/useDirectMemberDeclarationRead";
import DirectMemberStatusRoot from "../pages/direct_members/status/DirectMemberStatusRoot";
import DirectMemberSubmitRoot from "../pages/direct_members/submit/DirectMemberSubmitRoot";
import { useDirectMemberSubmitLoader } from "../pages/direct_members/submit/hooks/useDirectMemberSubmit";
import SubmitSectorsRoot from "../pages/direct_members/submit_sectors/SubmitSectorsRoot";
import { loadUseSubmitSectors } from "../pages/direct_members/submit_sectors/hooks/useSubmitSectors";
import Error from "../pages/error/Error";
import HomeRoot from "../pages/home/HomeRoot";
import { loadUseHome } from "../pages/home/hooks/useHome";
import Login from "../pages/login/Login";
import NationalAssociationRoot from "../pages/national_associations/NationalAssociationRoot";
import NationalAssociationChartsRoot from "../pages/national_associations/charts/NationalAssociationChartsRoot";
import { loadNationalAssociationCharts } from "../pages/national_associations/charts/hooks/useNationalAssociationCharts";
import NationalAssociationDeclarationReadRoot from "../pages/national_associations/read/NationalAssociationDeclarationReadRoot";
import { loadUseNationalAssociationDeclarationRead } from "../pages/national_associations/read/hooks/useNationalAssociationDeclarationRead";
import NationalAssociationStatusRoot from "../pages/national_associations/status/NationalAssociationStatusRoot";
import NationalAssociationSubmitRoot from "../pages/national_associations/submit/NationalAssociationSubmitRoot";
import { loadUseNationalAssociationSubmit } from "../pages/national_associations/submit/hooks/useNationalAssociationSubmit";
import NotFound from "../pages/not_found/NotFound";
import ResetPasswordRoot from "../pages/reset_password/ResetPasswordRoot";

const router = createBrowserRouter([
    {
        path: "/login",
        element: <Login />,
        errorElement: <Error />,
    },
    {
        path: "/submit/declaration-submitted",
        element: <DeclarationSubmitted />,
    },
    {
        path: "activate/:token",
        element: <ActivateRoot />,
    },
    {
        path: "reset_password",
        element: <ResetPasswordRoot />,
        handle: {
            crumb: () => <BreadCrumbLink label="Reset password" />,
        },
    },
    {
        path: "/error",
        element: <Error />,
    },
    {
        element: <HeaderLayoutRoot />,
        handle: {
            crumb: () => <BreadCrumbLink to="home" label="home" />,
        },
        loader: loadUseLayout,
        children: [
            {
                path: "/read",
                element: <RequireMemberProvider />,
                loader: loadUseRequireMember,
                errorElement: <Error />,
                children: [
                    {
                        path: "direct_member/:idDirectMemberDeclaration",
                        element: <DirectMemberDeclarationReadRoot />,
                        loader: ({ params }) =>
                            loadUseDirectMemberDeclarationRead(
                                params.idDirectMemberDeclaration
                            ),
                    },
                    {
                        path: "national_association/:idNationalAssociationDeclaration",
                        element: <NationalAssociationDeclarationReadRoot />,
                        loader: ({ params }) =>
                            loadUseNationalAssociationDeclarationRead(
                                params.idNationalAssociationDeclaration
                            ),
                        errorElement: <Error />,
                    },
                ],
            },
            {
                path: "/submit",
                element: <RequireMembershipProvider />,
                loader: loadUseRequireMembership,
                errorElement: <Error />,
                children: [
                    {
                        path: "direct_member/:idDirectMember/:idYear",
                        element: <DirectMemberSubmitRoot />,
                        loader: useDirectMemberSubmitLoader,
                    },
                    {
                        path: "national_association/:idNationalAssociation/:idYear",
                        element: <NationalAssociationSubmitRoot />,
                        loader: loadUseNationalAssociationSubmit,
                        errorElement: <Error />,
                    },
                ],
            },
        ],
    },
    {
        path: "/",
        element: <RequireAdmin />,
        loader: loadUseRequireAdmin,
        errorElement: <Error />,
        children: [
            {
                element: <SideBarLayoutRoot />,
                handle: {
                    crumb: () => <BreadCrumbLink to="home" label="home" />,
                },
                loader: loadUseLayout,
                children: [
                    {
                        index: true,
                        element: <HomeRoot />,
                        loader: loadUseHome,
                    },
                    {
                        path: "direct_member/submit_sectors_declaration",
                        element: <SubmitSectorsRoot />,
                        loader: loadUseSubmitSectors,
                    },
                    {
                        path: "direct_member",
                        element: <DirectMemberRoot />,
                        handle: {
                            crumb: () => (
                                <BreadCrumbLink label="Direct member" />
                            ),
                        },
                        children: [
                            {
                                path: "submit",
                                element: <DirectMemberSubmitRoot />,
                                handle: {
                                    crumb: () => (
                                        <BreadCrumbLink label="Submit" />
                                    ),
                                },
                                loader: useDirectMemberSubmitLoader,
                            },
                            {
                                path: "status",
                                element: <DirectMemberStatusRoot />,
                                handle: {
                                    crumb: () => (
                                        <BreadCrumbLink label="status" />
                                    ),
                                },
                                loader: loadUseYears,
                            },
                            {
                                path: "status/:idDeclaration/edit",
                                element: <DirectMemberSubmitRoot />,
                                handle: {
                                    crumb: () => (
                                        <BreadCrumbLink label="edit" />
                                    ),
                                },
                            },
                            {
                                path: "charts",
                                element: <ChartsRoot />,
                                handle: {
                                    crumb: () => (
                                        <BreadCrumbLink label="Charts" />
                                    ),
                                },
                                loader: loadCharts,
                            },
                        ],
                    },
                    {
                        path: "national_association",
                        element: <NationalAssociationRoot />,
                        handle: {
                            crumb: () => (
                                <BreadCrumbLink label="National Association" />
                            ),
                        },
                        children: [
                            {
                                path: "submit",
                                element: <NationalAssociationSubmitRoot />,
                                handle: {
                                    crumb: () => (
                                        <BreadCrumbLink label="Submit" />
                                    ),
                                },
                                loader: loadUseNationalAssociationSubmit,
                            },
                            {
                                path: "status",
                                element: <NationalAssociationStatusRoot />,
                                handle: {
                                    crumb: () => (
                                        <BreadCrumbLink label="status" />
                                    ),
                                },
                                loader: loadUseYears,
                            },
                            // {
                            //     path: "status/:idDeclaration/edit",
                            //     element: <DirectMemberSubmitRoot />,
                            //     handle: {
                            //         crumb: () => (
                            //             <BreadCrumbLink label="edit" />
                            //         ),
                            //     },
                            // },
                            {
                                path: "charts",
                                element: <NationalAssociationChartsRoot />,
                                handle: {
                                    crumb: () => (
                                        <BreadCrumbLink label="Charts" />
                                    ),
                                },
                                loader: loadNationalAssociationCharts,
                            },
                        ],
                    },
                ],
            },
        ],
    },

    {
        path: "*",
        element: <NotFound />,
        errorElement: <Error />,
    },
]);

export default router;
