import {
    Container,
    Skeleton,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { Suspense, lazy } from "react";

const Charts = lazy(() => import("./Charts"));

const ChartsFallback = () => (
    <Container maxWidth="xl">
        <Stack>
            <Typography variant="h1">Charts</Typography>
            <Stack direction="row">
                <ToggleButtonGroup exclusive aria-label="Sector">
                    {["Sector", "Sector", "Sector", "Sector"].map((edge, i) => (
                        <ToggleButton key={i} disabled>
                            {edge}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                <ToggleButtonGroup exclusive aria-label="text alignment">
                    <ToggleButton value={false} disabled>
                        Votes
                    </ToggleButton>
                    <ToggleButton value={true} disabled>
                        Revenue
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Skeleton variant="rectangular" height={800} />
        </Stack>
    </Container>
);

const ChartsRoot = () => (
    <Suspense fallback={<ChartsFallback />}>
        <Charts />
    </Suspense>
);

export default ChartsRoot;
