import PropTypes from "prop-types";
import {
    matchRoutes,
    Navigate,
    Outlet,
    useLoaderData,
    useLocation,
} from "react-router-dom";
import useRequiredMembership from "../hooks/useRequireMembership";

const RequireMembershipProvider = ({ children }) => {
    const queryRef = useLoaderData();
    const data = useRequiredMembership(queryRef);
    const location = useLocation();
    const matches = matchRoutes(
        [
            { path: "submit/direct_member/:idDirectMember/:idYear" },
            {
                path: "submit/national_association/:idNationalAssociation/:idYear",
            },
        ],
        location
    );

    // Check if user is logged in
    if (!data?.me) {
        return <Navigate to="/login" />;
    }

    if (data?.me?.hasSubmittedDeclarationActiveYear) {
        if (data?.me?.directMember?.declarations?.edges.length > 0) {
            return (
                <Navigate
                    to={`/read/direct_member/${data.me.directMember.declarations.edges[0].node.id}`}
                />
            );
        }
        if (data?.me?.nationalAssociation?.declarations?.edges.length > 0) {
            return (
                <Navigate
                    to={`/read/national_association/${data.me.nationalAssociation.declarations.edges[0].node.id}`}
                />
            );
        }

        return <Navigate to="/error" />;
    }

    const params = matches[0].params;
    // Check if user is member of the direct_member/national_association
    if ("idDirectMember" in params) {
        if (data?.me?.directMember?.id === params.idDirectMember) {
            return <Outlet />;
        }
    }

    if ("idNationalAssociation" in params) {
        if (
            data?.me?.nationalAssociation?.id === params.idNationalAssociation
        ) {
            return <Outlet />;
        }
    }

    // If not, redirect to login
    return <Navigate to="/login" />;
};

RequireMembershipProvider.propTypes = {
    children: PropTypes.element,
};
export default RequireMembershipProvider;
