import { styled } from "@mui/material/styles";

const HeaderSpacer = styled("div")(({ theme }) => ({
    // Component that forces the sidebar down
    // So that the drawer appears below the header
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default HeaderSpacer;
