import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { loadQuery, usePreloadedQuery } from "react-relay";
import RelayEnvironment from "../api/RelayEnvironment";

const RequireAdminQuery = graphql`
    query useRequireAdminQuery {
        me {
            id
            email
            isSuperuser
            isStaff
            hasSubmittedDeclarationActiveYear
            directMember {
                id
                declarations(filters: { declarationSubmitted: true }, last: 1) {
                    edges {
                        node {
                            id
                        }
                    }
                }
            }
            nationalAssociation {
                id
                declarations(filters: { declarationSubmitted: true }, last: 1) {
                    edges {
                        node {
                            id
                        }
                    }
                }
            }
        }
        allYears(filters: { submissionsOpen: true }, first: 1) {
            edges {
                node {
                    id
                    number
                }
            }
        }
    }
`;

export const loadUseRequireAdmin = () =>
    loadQuery(
        RelayEnvironment,
        RequireAdminQuery,
        {},
        {
            fetchPolicy: "network-only",
        }
    );

const useRequireAdmin = (queryRef) => {
    const data = usePreloadedQuery(RequireAdminQuery, queryRef);
    return data;
};

useRequireAdmin.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useRequireAdmin;
