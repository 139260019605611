/**
 * @generated SignedSource<<3e0d96ba644fe82a610f3b39152c4cbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "declarationSubmitted": true
    }
  },
  {
    "kind": "Literal",
    "name": "last",
    "value": 1
  }
],
v3 = [
  (v0/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSuperuser",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isStaff",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasSubmittedDeclarationActiveYear",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectMember",
        "kind": "LinkedField",
        "name": "directMember",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "DirectMemberDeclarationConnection",
            "kind": "LinkedField",
            "name": "declarations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DirectMemberDeclarationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DirectMemberDeclaration",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "declarations(filters:{\"declarationSubmitted\":true},last:1)"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NationalAssociation",
        "kind": "LinkedField",
        "name": "nationalAssociation",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "NationalAssociationDeclarationConnection",
            "kind": "LinkedField",
            "name": "declarations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NationalAssociationDeclarationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NationalAssociationDeclaration",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "declarations(filters:{\"declarationSubmitted\":true},last:1)"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useRequireMembershipQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useRequireMembershipQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "9a8e0c3e631a818e8e64828df4952a99",
    "id": null,
    "metadata": {},
    "name": "useRequireMembershipQuery",
    "operationKind": "query",
    "text": "query useRequireMembershipQuery {\n  me {\n    id\n    email\n    isSuperuser\n    isStaff\n    hasSubmittedDeclarationActiveYear\n    directMember {\n      id\n      name\n      declarations(filters: {declarationSubmitted: true}, last: 1) {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n    nationalAssociation {\n      id\n      name\n      declarations(filters: {declarationSubmitted: true}, last: 1) {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "2ff81a06ed2300aadbbc7f3a7353c451";

module.exports = node;
