import { Avatar, Box, Button, Toolbar } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Suspense, lazy } from "react";
import { ChevronDown } from "react-feather";
import { Outlet } from "react-router-dom";

const Layout = lazy(() => import("./Layout"));

export const LayoutFallback = () => {
    return (
        <Box display={"flex"}>
            <Grid container spacing={2} sx={{ width: "100%", height: "100%" }}>
                <Grid xs container>
                    <Grid xs={12}>
                        <MuiAppBar
                            position="sticky"
                            open={false}
                            sx={{
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                        >
                            <Toolbar variant="regular" disableGutters>
                                {/* {mdUp ? <LogoIcon /> : ""} */}

                                <Box flexGrow={1} />

                                <Button
                                    disabled
                                    startIcon={
                                        <Avatar
                                            sx={{ width: 50, height: 50 }}
                                        />
                                    }
                                >
                                    <ChevronDown />
                                </Button>
                            </Toolbar>
                        </MuiAppBar>
                    </Grid>
                    <Grid xs={12}>
                        <Outlet />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

const LayoutRoot = () => (
    <Suspense fallback={<LayoutFallback />}>
        <Layout />
    </Suspense>
);

export default LayoutRoot;
