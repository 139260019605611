import PropTypes from "prop-types";
import { Navigate, Outlet, useLoaderData } from "react-router-dom";
import useRequireAdmin from "../hooks/useRequireAdmin";

const RequireAdmin = ({ children }) => {
    const queryRef = useLoaderData();
    const data = useRequireAdmin(queryRef);

    if (!data?.me) {
        return <Navigate to="/login" />;
    }

    if (!data?.me.isStaff) {
        const dm = data?.me.directMember?.id;
        const na = data?.me.nationalAssociation?.id;

        if (data?.me?.hasSubmittedDeclarationActiveYear) {
            if (data?.me?.directMember?.declarations?.edges.length > 0) {
                return (
                    <Navigate
                        to={`/read/direct_member/${data.me.directMember.declarations.edges[0].node.id}`}
                    />
                );
            }
            if (data?.me?.nationalAssociation?.declarations?.edges.length > 0) {
                return (
                    <Navigate
                        to={`/read/national_association/${data.me.nationalAssociation.declarations.edges[0].node.id}`}
                    />
                );
            }
            return <Navigate to="/error" />;
        }

        const yearEdges = data?.allYears?.edges;
        const year = yearEdges?.length > 0 ? yearEdges[0].node.id : null;

        if (!year) {
            return <Navigate to="/error" />;
        }
        if (dm && year) {
            return <Navigate to={`/submit/direct_member/${dm}/${year}`} />;
        }
        if (na && year) {
            return (
                <Navigate to={`/submit/national_association/${na}/${year}`} />
            );
        }
        return <Navigate to="/login" />;
    }

    return <Outlet />;
};

RequireAdmin.propTypes = {
    children: PropTypes.element,
};
export default RequireAdmin;
