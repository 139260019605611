import { CssBaseline } from "@mui/material";
import {
    createTheme,
    ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
import React, { useMemo } from "react";
import componentsOverride from "./Overrides";
import shadows from "./Shadows";
import typography from "./Typography";

export const SidebarWidth = 265;
export const TopbarHeight = 70;

const ThemeProvider = ({ children }) => {
    const themeOptions = useMemo(
        () => ({
            typography,
            shadows,
            palette: {
                text: {
                    primary: "#1B1741",
                },
            },
            // palette: {
            //     ...data.palette,
            // },
        }),
        []
    );

    const theme = createTheme(themeOptions);
    theme.components = componentsOverride;

    return (
        <MUIThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </MUIThemeProvider>
    );
};

export default ThemeProvider;
