/**
 * @generated SignedSource<<3af84127d0b87ac520aef913691e6f52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "country",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "DirectMemberDeclaration",
    "kind": "LinkedField",
    "name": "directMemberDeclaration",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectMember",
        "kind": "LinkedField",
        "name": "directMember",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Year",
        "kind": "LinkedField",
        "name": "year",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "number",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedBy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Sector",
        "kind": "LinkedField",
        "name": "sectors",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ManufacturingSite",
        "kind": "LinkedField",
        "name": "manufacturingSites",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "region",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "products",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Subsidiary",
        "kind": "LinkedField",
        "name": "subsidiaries",
        "plural": true,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NonDirectOperation",
        "kind": "LinkedField",
        "name": "nonDirectOperations",
        "plural": true,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NationalAssociationConnection",
        "kind": "LinkedField",
        "name": "nationalAssociations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NationalAssociationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NationalAssociation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CountryConnection",
    "kind": "LinkedField",
    "name": "allCountries",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CountryEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "NationalAssociation",
                "kind": "LinkedField",
                "name": "nationalAssociations",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDirectMemberDeclarationReadQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDirectMemberDeclarationReadQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "38075060da2ea85d0d465df333e5df67",
    "id": null,
    "metadata": {},
    "name": "useDirectMemberDeclarationReadQuery",
    "operationKind": "query",
    "text": "query useDirectMemberDeclarationReadQuery(\n  $id: GlobalID!\n) {\n  directMemberDeclaration(id: $id) {\n    id\n    directMember {\n      name\n      id\n    }\n    year {\n      number\n      id\n    }\n    signedBy\n    date\n    sectors {\n      id\n      name\n    }\n    manufacturingSites {\n      id\n      name\n      country {\n        id\n      }\n      region\n      products {\n        id\n        name\n      }\n    }\n    subsidiaries {\n      id\n      name\n      country {\n        id\n      }\n    }\n    nonDirectOperations {\n      id\n      name\n      country {\n        id\n      }\n    }\n    nationalAssociations {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n  allCountries {\n    edges {\n      node {\n        id\n        name\n        nationalAssociations {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "cda847d34f1d94e271ea5a8359795e2c";

module.exports = node;
