import { useState } from "react";

const useOpenToggler = (initialState = false) => {
    const [state, setState] = useState(initialState);
    const handler = {
        open: () => setState(true),
        close: () => setState(false),
        toggle: () => setState((current) => !current),
    };

    return [state, handler];
};
useOpenToggler.propTypes = {};
export default useOpenToggler;
