/**
 * @generated SignedSource<<b872c1ad70a1cdb0e84ce0ac21fc40e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "SectorConnection",
  "kind": "LinkedField",
  "name": "allSectors",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SectorEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Sector",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "abbreviation",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Year",
  "kind": "LinkedField",
  "name": "year",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenue",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vote",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "order",
      "value": {
        "number": "ASC"
      }
    }
  ],
  "concreteType": "YearConnection",
  "kind": "LinkedField",
  "name": "allYears",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "YearEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Year",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "DirectMemberDeclarationConnection",
              "kind": "LinkedField",
              "name": "directMemberDeclarations",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DirectMemberDeclarationEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DirectMemberDeclaration",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DirectMember",
                          "kind": "LinkedField",
                          "name": "directMember",
                          "plural": false,
                          "selections": (v4/*: any*/),
                          "storageKey": null
                        },
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DirectMemberSectorDeclaration",
                          "kind": "LinkedField",
                          "name": "directmembersectordeclaration",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Sector",
                              "kind": "LinkedField",
                              "name": "sector",
                              "plural": false,
                              "selections": (v4/*: any*/),
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            (v7/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allYears(order:{\"number\":\"ASC\"})"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useChartsQuery",
    "selections": [
      (v2/*: any*/),
      (v8/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "useChartsData_directmember"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useChartsQuery",
    "selections": [
      (v2/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "filters",
            "value": {
              "appliaMember": true
            }
          }
        ],
        "concreteType": "DirectMemberConnection",
        "kind": "LinkedField",
        "name": "allDirectMembers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DirectMemberEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DirectMember",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DirectMemberDeclarationConnection",
                    "kind": "LinkedField",
                    "name": "declarations",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DirectMemberDeclarationEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DirectMemberDeclaration",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DirectMemberSectorDeclaration",
                                "kind": "LinkedField",
                                "name": "directmembersectordeclaration",
                                "plural": true,
                                "selections": [
                                  (v0/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Sector",
                                    "kind": "LinkedField",
                                    "name": "sector",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v6/*: any*/),
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allDirectMembers(filters:{\"appliaMember\":true})"
      }
    ]
  },
  "params": {
    "cacheID": "55ee63302e6de2ce9e03e4fda7148606",
    "id": null,
    "metadata": {},
    "name": "useChartsQuery",
    "operationKind": "query",
    "text": "query useChartsQuery {\n  allSectors {\n    edges {\n      node {\n        id\n        name\n        abbreviation\n      }\n    }\n  }\n  allYears(order: {number: ASC}) {\n    edges {\n      node {\n        id\n        number\n        directMemberDeclarations {\n          edges {\n            node {\n              id\n              directMember {\n                id\n                name\n              }\n              year {\n                id\n                number\n              }\n              directmembersectordeclaration {\n                id\n                sector {\n                  id\n                  name\n                }\n                revenue\n                vote\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  ...useChartsData_directmember\n}\n\nfragment useChartsData_directmember on Query {\n  allDirectMembers(filters: {appliaMember: true}) {\n    edges {\n      node {\n        id\n        name\n        declarations {\n          edges {\n            node {\n              id\n              year {\n                id\n                number\n              }\n              directmembersectordeclaration {\n                id\n                sector {\n                  name\n                }\n                revenue\n                vote\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "b1f648aef2446ef9e8c16788e68e78be";

module.exports = node;
