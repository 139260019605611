import {
    FormControlLabel,
    FormGroup,
    Checkbox as MuiCheckbox,
} from "@mui/material";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

const Checkbox = ({
    control,
    fieldName,
    rules = {},
    defaultValue = false,
    label = "",
    labelPlacement = "end",
    value = true,
    ...props
}) => {
    const {
        field: { onChange, onBlur, name, value: checkedValue, ref },
        fieldState: { error },
    } = useController({
        name: fieldName,
        control: control,
        rules: rules,
        defaultValue: defaultValue,
    });

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <MuiCheckbox
                        name={name}
                        inputRef={ref}
                        onBlur={onBlur}
                        onChange={(e) =>
                            onChange(e.target.checked ? value : false)
                        }
                        value={value}
                        checked={Boolean(checkedValue)}
                        defaultValue={defaultValue}
                        color={!error ? "primary" : "error"}
                        {...props}
                    />
                }
                labelPlacement={labelPlacement}
                label={label}
            />
        </FormGroup>
    );
};
Checkbox.propTypes = {
    control: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    rules: PropTypes.object,
    defaultValue: PropTypes.bool,
};
export default Checkbox;
