import PropTypes from "prop-types";
import {
    matchRoutes,
    Navigate,
    Outlet,
    useLoaderData,
    useLocation,
} from "react-router-dom";
import useRequiredMember from "../hooks/useRequireMember";

const RequireMemberProvider = ({ children }) => {
    const queryRef = useLoaderData();
    const data = useRequiredMember(queryRef);
    const location = useLocation();
    const matches = matchRoutes(
        [
            { path: "read/direct_member/:idDirectMemberDeclaration" },
            {
                path: "read/national_association/:idNationalAssociationDeclaration",
            },
        ],
        location
    );

    // If there are no matches (like at /read), redirect to the first declaration
    if (!matches) {
        if (data?.me?.directMember?.declarations.edges.length > 0) {
            return (
                <Navigate
                    to={`/read/direct_member/${data.me.directMember.declarations.edges[0].node.id}`}
                />
            );
        } else if (
            data?.me?.nationalAssociation?.declarations.edges.length > 0
        ) {
            return (
                <Navigate
                    to={`/read/national_association/${data.me.nationalAssociation.declarations.edges[0].node.id}`}
                />
            );
        } else {
            return <Navigate to="/error" />;
        }
    }

    // Check if user is logged in
    if (!data?.me) {
        return <Navigate to="/login" />;
    }
    if (data?.me?.isStaff) {
        return <Outlet />;
    }

    const params = matches[0].params;

    // Check if user is member of the direct_member/national_association
    if ("idDirectMemberDeclaration" in params) {
        if (
            data?.me?.directMember?.declarations.edges.find(
                (edge) => edge.node.id === params.idDirectMemberDeclaration
            )
        ) {
            return <Outlet />;
        }
    }

    if ("idNationalAssociationDeclaration" in params) {
        if (
            data?.me?.nationalAssociation?.declarations.edges.find(
                (edge) =>
                    edge.node.id === params.idNationalAssociationDeclaration
            )
        ) {
            return <Outlet />;
        }
    }

    return <Navigate to="/error" />;
};

RequireMemberProvider.propTypes = {
    children: PropTypes.element,
};
export default RequireMemberProvider;
