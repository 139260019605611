import axios from "axios";
import { parseJSON } from "date-fns";
import { Environment, Network, RecordSource, Store } from "relay-runtime";
import refreshTokenMutation from "./refreshToken";

export const getLocalAccessTokenExpiration = () =>
    window.localStorage.getItem("accessTokenExpiration");

export const getLocalAccessToken = () =>
    window.localStorage.getItem("accessToken");

export const getLocalRefreshToken = () =>
    window.localStorage.getItem("refreshToken");

export const setLocalAccessTokenExpiration = (date) => {
    window.localStorage.setItem("accessTokenExpiration", date);
};
export const setLocalAccessToken = (value) => {
    window.localStorage.setItem("accessToken", value);
};
export const setLocalRefreshToken = (value) => {
    window.localStorage.setItem("refreshToken", value);
};

export const logout = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("accessTokenExpiration");
    window.localStorage.removeItem("refreshToken");
};

export const apiClient = axios.create({
    baseURL: `${window.location.origin}/api/`,
    method: "POST",
    headers: {
        "Content-type": "application/json",
    },
});

apiClient.interceptors.request.use(
    async (config) => {
        const accesToken = getLocalAccessToken();
        const tokenExpiration = getLocalAccessTokenExpiration();
        const refreshToken = getLocalRefreshToken();

        // Check if accesToken is expired
        const AccessTokenDateValid = parseJSON(tokenExpiration) > new Date();

        // Determine JwtToken
        if (accesToken && AccessTokenDateValid) {
            config.headers.Authorization = `JWT ${accesToken}`;
            return config;
        } else if (refreshToken) {
            await axios({
                method: "POST",
                url: `${window.location.origin}/api/`,
                headers: {
                    "Content-type": "application/json",
                },
                data: {
                    query: refreshTokenMutation.params.text,
                    variables: {
                        input: {
                            refreshToken: refreshToken,
                            revokeRefreshToken: true,
                        },
                    },
                },
            }).then((response) => {
                let responseData = response.data.data.refreshToken;
                if (responseData.success) {
                    let accessToken = responseData.token.token;
                    let accessTokenExp = responseData.token.payload.exp;
                    let refreshToken = responseData.refreshToken.token;
                    setLocalAccessToken(accessToken);
                    setLocalAccessTokenExpiration(accessTokenExp);
                    setLocalRefreshToken(refreshToken);
                    config.headers.Authorization = `JWT ${accessToken}`;
                    // return config;
                }
                // return null;
            });
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const fetchGraphQL = async (
    operation,
    variables,
    cacheConfig,
    uploadables,
    ...props
) => {
    const { data } = await apiClient.post("/", {
        query: operation.text,
        variables,
    });
    return data;
};

const fetchRelay = async (
    params,
    variables,
    cacheConfig,
    uploadables,
    ...props
) => {
    return fetchGraphQL(params, variables, cacheConfig, uploadables, ...props);
};

const environment = new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
});

export default environment;
