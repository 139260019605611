import {
    Box,
    IconButton,
    List,
    Skeleton,
    Toolbar,
    Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { lazy, Suspense } from "react";
import {
    BarChart2,
    Calendar,
    Folder,
    Home,
    Menu,
    Search,
    Users,
} from "react-feather";

import HeaderSpacer from "../../components/appBar/HeaderSpacer";
import ListItem from "../../components/menu/ListItem";
import Drawer from "../components/Drawer";

const Layout = lazy(() => import("./Layout"));

const LayoutFallback = () => {
    return (
        <Box display={"flex"}>
            <Grid container spacing={2} sx={{ width: "100%", height: "100%" }}>
                <Grid xs="auto">
                    <Drawer open={false} variant="permanent">
                        <HeaderSpacer />
                        <List sx={{ flexGrow: 1 }}>
                            <ListItem
                                disabled
                                icon={
                                    <Home
                                        sx={{ color: "primary.contrastText" }}
                                    />
                                }
                            />
                            <ListItem
                                disabled
                                icon={
                                    <Users
                                        sx={{ color: "primary.contrastText" }}
                                    />
                                }
                            />

                            <ListItem
                                disabled
                                icon={
                                    <Calendar
                                        sx={{ color: "primary.contrastText" }}
                                    />
                                }
                            />
                            <ListItem
                                disabled
                                icon={
                                    <BarChart2
                                        sx={{ color: "primary.contrastText" }}
                                    />
                                }
                            />
                            <ListItem
                                disabled
                                icon={
                                    <Folder
                                        sx={{ color: "primary.contrastText" }}
                                    />
                                }
                            />
                        </List>
                        <Typography textAlign={"center"} variant="caption">
                            re:root ©
                        </Typography>
                    </Drawer>
                </Grid>
                <Grid xs container>
                    <Grid xs={12}>
                        <MuiAppBar
                            position="sticky"
                            open={false}
                            sx={{
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                        >
                            <Toolbar variant="regular" disableGutters>
                                {/* {mdUp ? <LogoIcon /> : ""} */}

                                <IconButton
                                    edge="start"
                                    aria-label="menu"
                                    sx={{
                                        color: "text.primary",
                                    }}
                                >
                                    <Menu />
                                </IconButton>

                                <IconButton color="text.primary">
                                    <Search />
                                </IconButton>

                                <Box flexGrow={1} />

                                <Skeleton
                                    variant="circular"
                                    width={30}
                                    height={30}
                                />
                                <Typography>
                                    <Skeleton width={100} />
                                </Typography>
                            </Toolbar>
                        </MuiAppBar>
                    </Grid>
                    <Grid xs={12}></Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

const LayoutRoot = () => (
    <Suspense fallback={<LayoutFallback />}>
        <Layout />
    </Suspense>
);

export default LayoutRoot;
