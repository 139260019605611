import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { loadQuery, useLazyLoadQuery } from "react-relay";
import RelayEnvironment from "../../../../api/RelayEnvironment";

const SubmitSectorsQuery = graphql`
    query useSubmitSectorsQuery {
        allYears {
            edges {
                node {
                    id
                    number
                }
            }
        }
        allDirectMembers(filters: { appliaMember: true }) {
            edges {
                node {
                    id
                    name
                }
            }
        }
        allSectors {
            edges {
                node {
                    id
                    name
                    abbreviation
                    aggregatedSector
                }
            }
        }
    }
`;

export const loadUseSubmitSectors = () =>
    loadQuery(RelayEnvironment, SubmitSectorsQuery);

const useSubmitSectors = () => {
    const data = useLazyLoadQuery(SubmitSectorsQuery);
    return data;
};

useSubmitSectors.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useSubmitSectors;
