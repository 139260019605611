import {
    Autocomplete,
    Card,
    CardContent,
    Container,
    Skeleton,
    TextField,
    Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import { Suspense, lazy } from "react";
import { Bar } from "react-chartjs-2";
import { options } from "./components/RevenueBarChart";

ChartJS.register(
    CategoryScale,
    Colors,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const DirectMemberStatus = lazy(() => import("./DirectMemberStatus"));
export const DirectMemberStatusFallback = () => (
    <Container maxWidth="lg">
        <Grid2 container direction="row" alignItems="stretch">
            <Grid2 xs={12} container>
                <Grid2 xs={12}>
                    <Card>
                        <CardContent>
                            <Autocomplete
                                disablePortal
                                loading={true}
                                options={[]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Year"
                                        fullWidth={true}
                                        disabled
                                    />
                                )}
                            />
                        </CardContent>
                    </Card>
                </Grid2>
            </Grid2>
        </Grid2>
    </Container>
);

export const DirectMemberStatusYearFallback = () => (
    <Grid2 container direction="row" alignItems="stretch">
        <Grid2 xs={6} md={4}>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent={"center"}>
                        <Typography variant="h5">Open</Typography>
                        <Typography variant="body2">
                            <Skeleton />
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Grid2>
        <Grid2 xs={6} md={4}>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent={"center"}>
                        <Typography variant="h5">published</Typography>
                        <Typography variant="body2">
                            <Skeleton />
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Grid2>
        <Grid2 xs={12} md={4}>
            <Card sx={{ height: "100%" }}>
                <CardContent sx={{ height: "inherit" }}>
                    <Grid2
                        container
                        sx={{ height: "100%" }}
                        alignItems="center"
                    >
                        <Grid2 xs={12}>
                            <Typography variant="h1" textAlign={"center"}>
                                <Skeleton />
                            </Typography>
                        </Grid2>
                    </Grid2>
                </CardContent>
            </Card>
        </Grid2>
        <Grid2 xs={12}>
            <Card>
                <CardContent>
                    <Bar
                        options={options}
                        data={{
                            labels: [],
                            datasets: [],
                        }}
                    />
                </CardContent>
            </Card>
        </Grid2>
        <Grid2 xs={12}>
            <Card>
                <DataGrid autoHeight rows={[]} columns={[]} loading />
            </Card>
        </Grid2>
    </Grid2>
);

const DirectMemberStatusRoot = () => (
    <Suspense fallback={<DirectMemberStatusFallback />}>
        <DirectMemberStatus />
    </Suspense>
);

export default DirectMemberStatusRoot;
