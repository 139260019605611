import { lazy, Suspense } from "react";

const Activate = lazy(() => import("./Activate"));

const ActivateFallback = () => <></>;

const ActivateRoot = () => {
    return (
        <Suspense fallback={<ActivateFallback />}>
            <Activate />
        </Suspense>
    );
};
export default ActivateRoot;
