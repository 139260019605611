import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Container,
    Skeleton,
    Stack,
    TextField,
    Typography,
    Unstable_Grid2 as Grid,
} from "@mui/material";
import { lazy, Suspense } from "react";

const NationalAssociationSubmit = lazy(() =>
    import("./NationalAssociationSubmit")
);

export const NationalAssociationSubmitFallback = () => (
    <Container maxWidth="lg">
        <Typography variant="h1">
            <Skeleton />
        </Typography>
        <Typography variant="body1">
            <Skeleton />
        </Typography>
        <Stack>
            <Box>
                <Typography variant="h3">General</Typography>
                <Card>
                    <CardContent>
                        <Grid container>
                            <Grid xs={12} md={6}>
                                <TextField label={"Company"} fullWidth disabled>
                                    <Skeleton />
                                </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField label={"Year"} fullWidth disabled>
                                    <Skeleton />
                                </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField
                                    label={"Signed By"}
                                    fullWidth
                                    disabled
                                >
                                    <Skeleton />
                                </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField label="Date" fullWidth disabled>
                                    <Skeleton />
                                </TextField>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <Box>
                <Typography variant="h3">Turnover</Typography>
                <Card>
                    <CardContent>
                        <Grid container alignItems="center">
                            {[1, 2, 3].map((i) => (
                                <Grid xs={12} container>
                                    <Grid xs={1}>
                                        <Checkbox />
                                    </Grid>
                                    <Grid xs={11}>
                                        <Skeleton />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <Grid container marginBottom={0} paddingBottom={0}>
                <Grid xs marginBottom={0} paddingBottom={0}>
                    <Typography variant="h3">Operations</Typography>
                </Grid>
                <Grid xs="auto" marginBottom={0} paddingBottom={0}>
                    <Typography variant="caption">
                        Present in Country
                    </Typography>
                </Grid>
            </Grid>

            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((edge) => (
                <Card key={edge}>
                    <CardContent>
                        <Grid container alignItems="center">
                            <Grid xs={12} container>
                                <Grid xs={11}>
                                    <Typography variant="h5">
                                        <Skeleton />
                                    </Typography>
                                </Grid>
                                <Grid xs={1}>
                                    <Checkbox>
                                        <Skeleton />
                                    </Checkbox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ))}
            <Button type="submit" fullWidth variant="contained" disabled>
                Submit Declaration
            </Button>
        </Stack>
    </Container>
);

const NationalAssociationRoot = () => (
    <Suspense fallback={<NationalAssociationSubmitFallback />}>
        <NationalAssociationSubmit />
    </Suspense>
);

export default NationalAssociationRoot;
