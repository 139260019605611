import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { loadQuery, usePreloadedQuery } from "react-relay";
import RelayEnvironment from "../../../../api/RelayEnvironment";

const NationalAssociationSubmitQuery = graphql`
    query useNationalAssociationSubmitQuery {
        allNationalAssociations {
            edges {
                node {
                    id
                    name
                }
            }
        }
        allYears {
            edges {
                node {
                    id
                    number
                }
            }
        }
        allDirectMembers(filters: { exists: true, appliaMember: true }) {
            edges {
                node {
                    ...useCountryPresence_directMember
                }
            }
        }
        allSectors(filters: { aggregatedSector: false }) {
            edges {
                node {
                    id
                    name
                    abbreviation
                }
            }
        }
        allProducts {
            edges {
                ...useProductsNationalAssociation_product
            }
        }
    }
`;

export const loadUseNationalAssociationSubmit = () =>
    loadQuery(RelayEnvironment, NationalAssociationSubmitQuery);

const useNationalAssociationSubmit = (queryRef) => {
    const data = usePreloadedQuery(NationalAssociationSubmitQuery, queryRef);
    return data;
};

useNationalAssociationSubmit.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useNationalAssociationSubmit;
