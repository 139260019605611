/**
 * @generated SignedSource<<81f69c7debcd7b03d9834a2afefc0d8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ObtainJSONWebTokenType",
    "kind": "LinkedField",
    "name": "refreshToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TokenType",
        "kind": "LinkedField",
        "name": "token",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TokenPayloadType",
            "kind": "LinkedField",
            "name": "payload",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "exp",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RefreshTokenType",
        "kind": "LinkedField",
        "name": "refreshToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "revoked",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "refreshTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "refreshTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ce44cb3fb4b8a7324900a14acf75817f",
    "id": null,
    "metadata": {},
    "name": "refreshTokenMutation",
    "operationKind": "mutation",
    "text": "mutation refreshTokenMutation(\n  $input: RefreshTokenInput!\n) {\n  refreshToken(input: $input) {\n    success\n    errors\n    token {\n      payload {\n        exp\n      }\n      token\n    }\n    refreshToken {\n      revoked\n    }\n  }\n}\n"
  }
};
})();

node.hash = "1a52faf805b63ac84feea129c1b543b0";

module.exports = node;
