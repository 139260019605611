import {
    Card,
    CardContent,
    Typography,
    Unstable_Grid2 as Grid,
} from "@mui/material/";

const NotFound = () => {
    return (
        <Grid
            alignItems="center"
            justifyContent="center"
            container
            sx={{
                height: "100vh",
                backgroundImage: `url("/pillar3.jpeg")`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                    t.palette.mode === "light"
                        ? t.palette.grey[50]
                        : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <Grid xs={3}>
                <Card>
                    <CardContent>
                        <Typography variant="body1">Page not found.</Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
export default NotFound;
