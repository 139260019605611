import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { loadQuery, usePreloadedQuery } from "react-relay";
import RelayEnvironment from "../api/RelayEnvironment";

export const LayoutQuery = graphql`
    query useLayoutQuery {
        me {
            id
            email
        }
    }
`;

export const loadUseLayout = () => loadQuery(RelayEnvironment, LayoutQuery);

const useLayout = (queryRef) => {
    const data = usePreloadedQuery(LayoutQuery, queryRef);
    return data?.me;
};

useLayout.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useLayout;
