import {
    List,
    ListItem as MuiListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import PropTypes from "prop-types";
import { Children, forwardRef } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import {
    Link as RouterLink,
    useMatch,
    useResolvedPath,
} from "react-router-dom";
import useOpenToggler from "../../hooks/useOpenToggler";

const ListItemMultipleChildren = ({
    icon = <></>,
    title = "",
    to = "",
    onClick = () => {},
    children,
    ...props
}) => {
    const [open, { toggle }] = useOpenToggler();

    return (
        <>
            <MuiListItem disablePadding onClick={toggle}>
                <ListItemButton>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText
                        primary={title}
                        primaryTypographyProps={{ textTransform: "capitalize" }}
                    />
                    {open ? <ChevronUp /> : <ChevronDown />}
                </ListItemButton>
            </MuiListItem>
            <Collapse in={open} timeout="auto">
                <List disablePadding>{children}</List>
            </Collapse>
        </>
    );
};

const Link = forwardRef(function Link(itemProps, ref) {
    return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

const ListItemNoChildren = ({
    icon = <></>,
    title = null,
    to = null,
    onClick = () => {},
    ...props
}) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: false });

    return (
        <MuiListItem disablePadding onClick={onClick} {...props}>
            <ListItemButton component={Link} to={to} selected={Boolean(match)}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText
                    primary={title}
                    primaryTypographyProps={{ textTransform: "capitalize" }}
                />
            </ListItemButton>
        </MuiListItem>
    );
};

const ListItem = (props) => {
    if (Children.toArray(props.children).length === 0) {
        return <ListItemNoChildren {...props} />;
    }
    return <ListItemMultipleChildren {...props} />;
};

ListItem.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.element,
    children: PropTypes.any,
    onSidebarClose: PropTypes.func,
    handleClick: PropTypes.func,
    to: PropTypes.string,
};

export default ListItem;
