import {
    Breadcrumbs as MuiBreadcrumbs,
    Link as MuiLink,
    Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { cloneElement } from "react";
import { Link, useMatches } from "react-router-dom";

export const BreadCrumbLink = ({ to, label }) => {
    if (to) {
        return (
            <MuiLink
                component={Link}
                to={to}
                underline="hover"
                color="inherit"
                textTransform={"capitalize"}
            >
                {label}
            </MuiLink>
        );
    } else {
        return (
            <Typography color="text.primary" textTransform={"capitalize"}>
                {label}
            </Typography>
        );
    }
};
BreadCrumbLink.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string,
};

const Breadcrumbs = () => {
    let matches = useMatches();
    let crumbs = matches
        // first get rid of any matches that don't have handle and crumb
        .filter((match) => Boolean(match.handle?.crumb))
        // now map them into an array of elements, passing the loader
        // data to each one
        .map((match) => match.handle.crumb(match.data));

    return (
        <MuiBreadcrumbs aria-label="breadcrumb">
            {crumbs.map((crumb, i) =>
                cloneElement(crumb, { key: `crumb-${i}` })
            )}
        </MuiBreadcrumbs>
    );
};

export default Breadcrumbs;
