import { lazy, Suspense } from "react";
import { NationalAssociationSubmitFallback } from "../submit/NationalAssociationSubmitRoot";

const DirectMemberRead = lazy(() =>
    import("./NationalAssociationDeclarationRead")
);

const NationalAssociationDeclarationReadRoot = () => (
    <Suspense fallback={<NationalAssociationSubmitFallback />}>
        <DirectMemberRead />
    </Suspense>
);

export default NationalAssociationDeclarationReadRoot;
