/**
 * @generated SignedSource<<3a9b82500b9bf2f3be7632c471b670c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "YearConnection",
    "kind": "LinkedField",
    "name": "allYears",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "YearEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Year",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useNationalAssociationChartsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useNationalAssociationChartsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e41d1ca9d043ffabf0b46256f21c4d48",
    "id": null,
    "metadata": {},
    "name": "useNationalAssociationChartsQuery",
    "operationKind": "query",
    "text": "query useNationalAssociationChartsQuery {\n  allYears {\n    edges {\n      node {\n        id\n        number\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "7bdbb7d9087b8f39d3a4075feaa11184";

module.exports = node;
