import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";

const LoginMutation = graphql`
    mutation useLoginMutation($input: ObtainJSONWebTokenInput!) {
        tokenAuth(input: $input) {
            success
            errors
            token {
                payload {
                    exp
                }
                token
            }
            refreshToken {
                token
            }
        }
    }
`;

const useLoginMutation = () => {
    const [commitMutation, isMutationInFlight] = useMutation(LoginMutation);
    return [commitMutation, isMutationInFlight];
};

export default useLoginMutation;
