import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { loadQuery, usePreloadedQuery } from "react-relay";
import RelayEnvironment from "../api/RelayEnvironment";

export const YearsQuery = graphql`
    query useYearsQuery {
        allYears {
            edges {
                node {
                    id
                    number
                }
            }
        }
    }
`;

export const loadUseYears = () => loadQuery(RelayEnvironment, YearsQuery);

const useYears = (queryRef) => {
    const data = usePreloadedQuery(YearsQuery, queryRef);
    return data?.allYears;
};

useYears.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useYears;
