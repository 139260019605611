import { lazy, Suspense } from "react";

const Home = lazy(() => import("./Home"));
const HomeFallback = () => <></>;

const HomeRoot = () => (
    <Suspense fallback={<HomeFallback />}>
        <Home />
    </Suspense>
);

export default HomeRoot;
