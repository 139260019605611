import { lazy, Suspense } from "react";

const SubmitSectors = lazy(() => import("./SubmitSectors"));

export const SubmitSectorsFallback = () => <></>;

const SubmitSectorsRoot = () => (
    <Suspense fallback={<SubmitSectorsFallback />}>
        <SubmitSectors />
    </Suspense>
);

export default SubmitSectorsRoot;
