const components = {
    MuiCssBaseline: {
        styleOverrides: {
            "*": {
                boxSizing: "border-box",
            },
            html: {
                height: "100%",
                width: "100%",
            },
            body: {
                height: "100%",
                margin: 0,
                padding: 0,
            },
            "#root": {
                // height: "100%",
                minHeight: "100vh",
                backgroundColor: "#fafbfb",
            },
            ".MuiCardHeader-action": {
                alignSelf: "center !important",
            },
            ".scrollbar-container": {
                borderRight: "0 !important",
            },
        },
    },
    MuiStack: {
        defaultProps: {
            spacing: 2,
        },
    },
    MuiGrid: {
        defaultProps: {
            spacing: 2,
        },
    },
    MuiGridItem: {
        styleOverrides: {
            root: {
                // paddingTop: "30px",
                // paddingLeft: "30px !important",
            },
        },
    },
    MuiContainer: {
        styleOverrides: {
            root: {
                // paddingLeft: "15px !important",
                // paddingRight: "15px !important",
                maxWidth: "1600px",
            },
        },
    },
    MuiAppBar: {
        defaultProps: {
            color: "transparent",
            elevation: 0,
        },
    },
    MuiTextField: {
        defaultProps: {
            variant: "outlined",
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: "none",
                boxShadow: "none",
                // fontSize: "15px",
                "&:hover": {
                    boxShadow: "none",
                },
            },
        },
    },

    MuiListItem: {
        styleOverrides: {
            root: {
                borderRadius: "9px",
            },
        },
    },

    MuiCard: {
        styleOverrides: {
            root: {
                borderRadius: "8px",
                padding: "0px",
                margin: "0px",
                boxShadow: "0px 7px 30px 0px rgba(90, 114, 123, 0.11)",
            },
        },
    },

    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: "40px",
            },
        },
    },

    MuiLinearProgress: {
        styleOverrides: {
            root: {
                backgroundColor: "#ecf0f2",
                borderRadius: "6px",
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                borderRadius: "0",
            },
        },
    },
    MuiChip: {
        styleOverrides: {
            root: {
                fontWeight: "500",
                fontSize: "0.75rem",
            },
        },
    },
};

export default components;
