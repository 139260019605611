import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import useRevenueBarChart from "../hooks/useRevenueBarChart";
import useSectors from "../hooks/useSectors";

ChartJS.register(
    CategoryScale,
    Colors,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    plugins: {
        title: {
            display: true,
            text: "Direct Member Revenues",
        },
        colors: {
            forceOverride: true,
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

const RevenueBarChart = ({ queryRefSectors, queryRefData }) => {
    const data = useRevenueBarChart(queryRefData);
    const sectors = useSectors(queryRefSectors);

    const labels = data?.map((edge) => {
        const dm = edge.node;
        return dm.directMember.name;
    });

    const dataSets = sectors
        .filter((sector) => !sector.aggregatedSector)
        .map((sector, index) => {
            const dataSet = data?.map((edge) => {
                const dm = edge.node;
                const filteredSector = dm.directmembersectordeclaration.find(
                    (sectorDeclaration) =>
                        sectorDeclaration.sector.id === sector.id
                );
                const revenue = parseInt(filteredSector?.revenue);
                if (!revenue) {
                    return 0;
                }
                return revenue;
            });

            return {
                id: index,
                label: sector.name,
                data: dataSet,
                // backgroundColor: "rgb(255, 99, 132)",
            };
        });

    return (
        <Bar
            options={options}
            data={{
                labels,
                datasets: dataSets,
            }}
        />
    );
};

RevenueBarChart.propTypes = {
    queryRefSectors: PropTypes.array.isRequired,
    queryRefData: PropTypes.array.isRequired,
};

export default RevenueBarChart;
