/**
 * @generated SignedSource<<22d6e0b02e5cc4fc7a75cd5c215b3a88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "declarationSubmitted",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "filters",
        "value": {
          "submissionsOpen": true
        }
      },
      {
        "kind": "Literal",
        "name": "first",
        "value": 1
      }
    ],
    "concreteType": "YearConnection",
    "kind": "LinkedField",
    "name": "allYears",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "YearEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Year",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submissionsOpen",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NationalAssociationDeclarationConnection",
                "kind": "LinkedField",
                "name": "nationalAssociationDeclarations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NationalAssociationDeclarationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NationalAssociationDeclaration",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NationalAssociation",
                            "kind": "LinkedField",
                            "name": "nationalAssociation",
                            "plural": false,
                            "selections": (v0/*: any*/),
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DirectMemberDeclarationConnection",
                "kind": "LinkedField",
                "name": "directMemberDeclarations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DirectMemberDeclarationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DirectMemberDeclaration",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DirectMember",
                            "kind": "LinkedField",
                            "name": "directMember",
                            "plural": false,
                            "selections": (v0/*: any*/),
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sectorDeclarationSubmitted",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "allYears(filters:{\"submissionsOpen\":true},first:1)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useHomeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useHomeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "df739ee8727da459cab95b2c6beae5f5",
    "id": null,
    "metadata": {},
    "name": "useHomeQuery",
    "operationKind": "query",
    "text": "query useHomeQuery {\n  allYears(filters: {submissionsOpen: true}, first: 1) {\n    edges {\n      node {\n        id\n        number\n        submissionsOpen\n        nationalAssociationDeclarations {\n          edges {\n            node {\n              nationalAssociation {\n                name\n              }\n              declarationSubmitted\n            }\n          }\n        }\n        directMemberDeclarations {\n          edges {\n            node {\n              directMember {\n                name\n              }\n              declarationSubmitted\n              sectorDeclarationSubmitted\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "59aee1f335eeb10ba4aef3f36a9393db";

module.exports = node;
