import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { useFragment } from "react-relay";

export const SectorsQuery = graphql`
    fragment useSectors_Query on SectorEdge @relay(plural: true) {
        node {
            id
            name
            abbreviation
            aggregatedSector
        }
    }
`;

const useSectors = (queryRef) => {
    const data = useFragment(SectorsQuery, queryRef);
    return data.map((item) => item.node);
};

useSectors.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useSectors;
