import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { loadQuery, usePreloadedQuery } from "react-relay";
import RelayEnvironment from "../../../../api/RelayEnvironment";

const DirectMemberDeclarationRead = graphql`
    query useDirectMemberDeclarationReadQuery($id: GlobalID!) {
        directMemberDeclaration(id: $id) {
            id
            directMember {
                name
                id
            }
            year {
                number
                id
            }
            signedBy
            date
            sectors {
                id
                name
            }
            manufacturingSites {
                id
                name
                country {
                    id
                }
                region
                products {
                    id
                    name
                }
            }
            subsidiaries {
                id
                name
                country {
                    id
                }
            }
            nonDirectOperations {
                id
                name
                country {
                    id
                }
            }
            nationalAssociations {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
        allCountries {
            edges {
                node {
                    id
                    name
                    nationalAssociations {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const loadUseDirectMemberDeclarationRead = (id) =>
    loadQuery(
        RelayEnvironment,
        DirectMemberDeclarationRead,
        { id: id },
        { fetchPolicy: "store-or-network" }
    );

const useDirectMemberDeclarationRead = (queryRef) => {
    const data = usePreloadedQuery(DirectMemberDeclarationRead, queryRef);
    return data;
};

useDirectMemberDeclarationRead.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useDirectMemberDeclarationRead;
