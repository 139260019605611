import { graphql } from "babel-plugin-relay/macro";

const refreshTokenMutation = graphql`
    mutation refreshTokenMutation($input: RefreshTokenInput!) {
        refreshToken(input: $input) {
            success
            errors
            token {
                payload {
                    exp
                }
                token
            }
            refreshToken {
                revoked
            }
        }
    }
`;

export default refreshTokenMutation;
