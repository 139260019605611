import {
    Autocomplete,
    Card,
    CardContent,
    Container,
    Skeleton,
    TextField,
    Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Suspense, lazy } from "react";

const NationalAssociationStatus = lazy(() =>
    import("./NationalAssociationStatus")
);
const NationalAssociationStatusFallback = () => (
    <Container maxWidth="lg">
        <Grid2 container direction="row" alignItems="stretch">
            <Grid2 xs={12} container>
                <Card>
                    <CardContent>
                        <Autocomplete
                            disablePortal
                            loading={true}
                            options={[]}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Year"
                                    fullWidth={true}
                                    disabled
                                />
                            )}
                        />
                    </CardContent>
                </Card>
            </Grid2>
        </Grid2>
    </Container>
);

export const NationalAssociationStatusYearFallback = () => (
    <Grid2 container direction="row" alignItems="stretch" xs={12}>
        <Grid2 xs={6} md={4}>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent={"center"}>
                        <Typography variant="h5">Open</Typography>
                        <Typography variant="body2">
                            <Skeleton />
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Grid2>
        <Grid2 xs={6} md={4}>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent={"center"}>
                        <Typography variant="h5">published</Typography>
                        <Typography variant="body2">
                            <Skeleton />
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Grid2>
        <Grid2 xs={12} md={4}>
            <Card sx={{ height: "100%" }}>
                <CardContent sx={{ height: "inherit" }}>
                    <Grid2
                        container
                        sx={{ height: "100%" }}
                        alignItems="center"
                    >
                        <Grid2 xs={12}>
                            <Typography variant="h1" textAlign={"center"}>
                                <Skeleton />
                            </Typography>
                        </Grid2>
                    </Grid2>
                </CardContent>
            </Card>
        </Grid2>

        <Grid2 xs={12}>
            <Card>
                <DataGrid autoHeight rows={[]} columns={[]} loading />
            </Card>
        </Grid2>
    </Grid2>
);

const NationalAssociationStatusRoot = () => (
    <Suspense fallback={<NationalAssociationStatusFallback />}>
        <NationalAssociationStatus />
    </Suspense>
);

export default NationalAssociationStatusRoot;
