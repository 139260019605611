import { graphql } from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { loadQuery, usePreloadedQuery } from "react-relay";
import RelayEnvironment from "../../../../api/RelayEnvironment";

export const ChartsQuery = graphql`
    query useChartsQuery {
        allSectors {
            edges {
                node {
                    id
                    name
                    abbreviation
                }
            }
        }
        allYears(order: { number: ASC }) {
            edges {
                node {
                    id
                    number
                    directMemberDeclarations {
                        edges {
                            node {
                                id
                                directMember {
                                    id
                                    name
                                }
                                year {
                                    id
                                    number
                                }
                                directmembersectordeclaration {
                                    id
                                    sector {
                                        id
                                        name
                                    }
                                    revenue
                                    vote
                                }
                            }
                        }
                    }
                }
            }
        }
        ...useChartsData_directmember
    }
`;

export const loadCharts = () => loadQuery(RelayEnvironment, ChartsQuery, {});

const useCharts = (queryRef) => {
    const data = usePreloadedQuery(ChartsQuery, queryRef);
    return data;
};

useCharts.propTypes = {
    queryRef: PropTypes.object.isRequired,
};

export default useCharts;
